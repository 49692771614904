import React from "react";
import SocialButtons from './SocialButtons';
import { useState,useEffect } from "react";
import SectionTitle from "./SectionTitle";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

export default function ContactSupport() {
	const { t,i18n } = useTranslation();
	const currentLanguage = i18n.language;

	const [questions, getFaq] = useState([]);

	useEffect(() => {
		fetch("/faq-" + currentLanguage + ".json")
			.then((res) => res.json())
			.then((data) => getFaq(data));
	}, [currentLanguage]);
	
	return (
		<>
			<div className="contact-support container-fluid mb-5 py-lg-5 text-center">
				<div className="container px-0">
					<SectionTitle title={t('contact-title')}></SectionTitle>
					{/* <SocialButtons /> */}
				</div>
				<div className="container mt-5">
					<div className="row">
						<Accordion className="questions" defaultActiveKey={1}>
							{questions.map((question) => {
								return (
									<Accordion.Item className="border-0" key={question.id} eventKey={question.id} >
										<Accordion.Header>{question.question}</Accordion.Header>
										<Accordion.Body dangerouslySetInnerHTML={{ __html: question.answer }} className="text-start pt-4"></Accordion.Body>
									</Accordion.Item>
								);
							})}
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}