import React from 'react'
import SectionTitle from './SectionTitle'
import flagRU from "../assets/images/flag-ru.svg";
import flagTR from "../assets/images/flag-tr.svg";
import iconPhone from "../assets/images/icon-phone.svg";
import iconEnvelope from "../assets/images/icon-envelope.svg";
import iconMap from "../assets/images/icon-map.svg";
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
	const { t } = useTranslation();

	return (
		<>
			<SectionTitle title={t('contact-us')} />
			<div id='contact-us' className="container contact-us">
				<div className="row mb-4">
					<div className="col-12 col-lg-4 offset-lg-4 bg-light p-4 p-lg-5 headquarters">
						<p className="h4 fw-bold mb-4 d-none">{t('headquarters')}</p>
						<div className="d-flex flex-wrap p-0">
							<ul className="col-12 list-unstyled">
								<li>
									<img src={flagRU} alt="" />
									<span className="fw-semibold">Russia</span>
								</li>
								<li className='d-none'>
									<img src={iconPhone} alt="" />
									<span>+7 918 279 55 55</span>
								</li>
								<li>
									<img src={iconEnvelope} alt="" />
									<span>info@assistyonline.ru</span>
								</li>
								<li className='align-items-start'>
									<img src={iconMap} alt="" />
									<span>
										ASSISTY LLC 117208, г. Москва, Вн.Тер.Г.
										<br />
										Муниципальный Округ Чертаново
										<br />
										Северное, Ул. Чертановская, д. 7А, Пом. 6П
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
