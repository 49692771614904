import React, {useEffect} from 'react';
import iconOnMap from "../assets/images/icon-on-map.svg";
import iconPin from "../assets/images/icon-map-assisty-filled-inside.png";
import {useTranslation} from "react-i18next";

export default function MapWithMarkers({userLocation, locations}) {

	const {t} = useTranslation();
	useEffect(() => {
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}`;
		script.async = true;
		script.defer = true;
		// window.initMap = initMap;
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
			delete window.initMap;
		};
	}, [process.env.REACT_APP_API_KEY]);

	function initMap() {
		if (locations.length > 0) {
			const map = new window.google.maps.Map(
				document.getElementById("places-map"),
				{
					center: {lat: locations[0].lat, lng: locations[0].lng},
					// center: {lat: userLocation.userLat, lng: userLocation.userLng},
					zoom: 11,
				}
			);

			let currentInfoWindow = null;
			const closeInfoWindow = () => {
				if (currentInfoWindow) {
					currentInfoWindow.close();
					currentInfoWindow = null;
				}
			};

			locations.forEach((place) => {
				const customIcon = {
					url: iconPin,
					scaledSize: new window.google.maps.Size(32, 32), // size
					origin: new window.google.maps.Point(0, 0), // origin(top-left corner)
					anchor: new window.google.maps.Point(20, 40), // anchor point (center-bottom)
				};
				const marker = new window.google.maps.Marker({
					position: {lat: place.lat, lng: place.lng},
					map: map,
					icon: customIcon
				});
				const infowindow = new window.google.maps.InfoWindow({
					content: `<div><h5>${place.name}</h5><p>${place.city}/${place.district}</p><p>${place.address}</p>${place.directions}</div>`,
				});

				marker.addListener("click", () => {
					closeInfoWindow();
					infowindow.open(map, marker);
					currentInfoWindow = infowindow;
				});
			});

			// User Location
			const markerUser = new window.google.maps.Marker({
				position: {lat: userLocation.userLat, lng: userLocation.userLng},
				map: map
			});

			document.getElementById("places-map").classList.remove("d-none");
		}
	};
	return (
		<>
			<button className="btn btn-qr d-block mb-4 mx-auto show-on-map d-none" onClick={initMap}>
				<img src={iconOnMap} alt="icon" style={{height:'25px'}} />
				<span className="ms-2">{t('show-on-map')}</span>
			</button>
			<div id="places-map" className='mb-4 d-none' style={{height: "400px", width: "100%"}}></div>
		</>
	);
};