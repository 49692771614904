import React from 'react';
import SectionTitle from './SectionTitle';
import PaymentButton from '../components/PaymentButton';
import payWithQR from "../assets/images/pay-with-qr.jpg"
import payWithQRRu from "../assets/images/pay-with-qr-ru.jpg"
import payWithCode from "../assets/images/pay-with-code.jpg"
import payWithCodeRu from "../assets/images/pay-with-code-ru.jpg"
import { useTranslation } from 'react-i18next';

export default function HowItWorks() {
    const { t,i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div id='how-it-works' className="how-it-works container d-flex flex-column justifiy-content-center text-center pt-3 px-0 mb-5">
            <div className="text-center mt-5 mt-lg-0">
				<PaymentButton classes="btn btn-qr mx-auto px-3 py-2 d-inline-block d-lg-none" textClass="ms-2" />
			</div>
            <SectionTitle title={t('how-it-works')} subtitle={t('pay-expenses')} />
            {currentLanguage === "ru" ?
                <>
                    <img src={payWithQRRu} alt="img" />
                    <img src={payWithCodeRu} alt="img" className='mt-3 mt-lg-5' /></> :
                <>
                    <img src={payWithQR} alt="img" />
                    <img src={payWithCode} alt="img" className="mt-3 my-lg-5" />
                </>
            }
        </div>
    );
}