import React from 'react'
import Header from "./Header"
import iconRestriction from "../assets/images/icon-restriction.svg"
import iconFacebook from "../assets/images/icon-facebook.svg"
import iconInstagram from "../assets/images/icon-instagram.svg"
import iconTwitter from "../assets/images/icon-twitter.svg"
import iconVk from "../assets/images/icon-vk.svg"
import { useTranslation } from 'react-i18next';

export default function Footer() {
	const { t } = useTranslation();
	return (
		<footer className="container">
			<div className="row">
				<div className="col-12 col-lg-8 p-4 p-lg-5 footer-left">
					<div className="d-flex flex-wrap">
						<div className="footer-left-top w-100">
							<Header button={false} mainClass="container position-relative" />
							<div className="d-flex flex-wrap justify-content-between align-items-center border-top mt-4 pt-4">
								<span className="text-white px-0" style={{fontSize: "12px"}}>
									{t('footer-motto-line-1')}
									<br />
									{t('footer-motto-line-2')}
								</span>
								<ul className="list-unstyled d-flex mb-0 mt-3 mt-lg-0 social-icons">
									<li className="me-4 me-lg-0 ms-0 ms-lg-3">
										<a href="https://www.facebook.com/assistyonline" title="Facebook" target="_blank" rel="noopener noreferrer">
											<img src={iconFacebook} alt="icon" />
										</a>
									</li>
									<li className="me-4 me-lg-0 ms-0 ms-lg-3">
										<a href="https://www.instagram.com/assistyonline.ru/" title="Instagram" target="_blank" rel="noopener noreferrer">
											<img src={iconInstagram} alt="icon" />
										</a>
									</li>
									<li className="me-4 me-lg-0 ms-0 ms-lg-3">
										<a href="https://twitter.com/AssistyOnline" title="X" target="_blank" rel="noopener noreferrer">
											<img src={iconTwitter} alt="icon" />
										</a>
									</li>
									<li className="me-4 me-lg-0 ms-0 ms-lg-3">
										<a href="https://vk.com/assistyonline" title="VK" target="_blank" rel="noopener noreferrer">
											<img src={iconVk} alt="icon" />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg-4 p-4 p-lg-5 footer-right"></div>
			</div>
			<div className="row">
				<div className='d-center flex-column flex-md-row my-3' style={{fontSize: "12px"}}>
					<span>{t('company-name')}</span>
					<span className="d-none d-md-inline mx-md-1">|</span>
					<span className='my-2 my-md-0'>{t('copyright')}</span>
					<img
						src={iconRestriction}
						alt="img"
						className="ms-0 ms-lg-3"
						height={"40px"}
					/>
				</div>
			</div>
		</footer>
	);
}