import React from 'react';
import SocialButtons from './SocialButtons';
import MobileButton from './MobileButton';
import logo from "../assets/images/assisty-logo.svg";
import kassaIcon from "../assets/images/kassa-turkey.svg";
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';


export default function HeaderTop({button, socialButtons, mobileButton, mainClass}) {
	const { t } = useTranslation();

	function scrollTop() {
		return (
			window.scrollTo({ top: 0, behavior: 'smooth' })
		)
	}

	return (
		<div className={mainClass} style={{zIndex: "2"}}>
			<div className="row align-items-center">
				<div className="align-items-center col-6 col-lg-3 d-flex px-0">
					{mobileButton ? <MobileButton /> : null}
					<img src={logo} alt="Assisty" className='menu-logo' onClick={scrollTop} />
				</div>
				<div className="d-none d-lg-flex col-lg-6 px-0 nav">
					<ul className="d-flex justify-content-center list-unstyled mx-auto my-0 p-0">
						<li key={1}><a href="#all-locations">{t('payment-points')}</a></li>
						<li key={2}><a href="#how-it-works">{t('how-it-works')}</a></li>
						<li key={3}><a href="#contact-us">{t('contact-us')}</a></li>
					</ul>
				</div>
				<div className="col-6 col-lg-3 px-0 d-flex align-items-center justify-content-end">
					{button && <img src={kassaIcon} alt="img" className="yoomoney-logo d-inline-block me-3" />}
					<LanguageSwitcher/>
					{socialButtons ? <SocialButtons /> : null}
					{/* {button ? <PaymentButton classes="btn btn-qr ms-3 px-3 py-2" textClass="ms-2 d-none d-xl-inline" /> : null} */}
				</div>
			</div>
		</div>
	);
}