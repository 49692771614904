import React from 'react';
import SectionTitle from './SectionTitle';
import touristPassImage from '../assets/images/ist-tourist-pass.jpg';
import {useTranslation} from "react-i18next";

export default function TouristPass() {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <>
            <div id='istanbul-tourist-pass' className="istanbul-tourist-pass container d-flex flex-column justifiy-content-center text-center pt-3 px-0 mb-5">
                {/* <SectionTitle title={t('istanbul-tourist-pass-title')} subtitle={t('istanbul-tourist-pass-subtitle')} /> */}
                <div className="container rounded-5" style={{backgroundColor: "#fff1f5"}}>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-5 p-5">
                            <img src={touristPassImage} alt="Istanbul Tourist Pass" className='w-100' />
                        </div>
                        <div className="col-12 col-lg-7 py-4 d-center flex-column">
                            <h4 className='mb-4'>{t('istanbul-tourist-pass-text-title')}</h4>
                            <div dangerouslySetInnerHTML={{ __html: t('istanbul-tourist-pass-text-description') }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}