import React from 'react'
import iconMinus from "../assets/images/icon-minus-white.svg";

export default function MobileButton() {
  return (
    <span className="d-flex d-lg-none flex-column justify-content-between me-2 menu-icon">
        <img src={iconMinus} alt="icon" />
        <img src={iconMinus} alt="icon" />
        <img src={iconMinus} alt="icon" />
    </span>
  )
}
