import React from 'react'
import flagRU from "../assets/images/flag-ru.svg";
import flagEN from "../assets/images/flag-en.svg";
import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
        <button className='btn border-0 p-0' onClick={() => changeLanguage('ru')}><img src={flagRU} alt="Russian" height={"30px"} /></button>
        <button className='btn border-0 p-0' onClick={() => changeLanguage('en')}><img src={flagEN} alt="English" height={"30px"} className="ms-2" /></button>
    </>
  );
}
