import React from 'react'
import SectionTitle from './SectionTitle'
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
    const { t } = useTranslation();
    return (
    <div>
        <SectionTitle title={t('about-us')} className="pt-4 pt-lg-5" />
        <div id='about-us' className='col-12 col-lg-8 w-lg-50 mx-auto offset-lg-2 px-3 text-center' dangerouslySetInnerHTML={{ __html: t('about-us-description') }}></div>
    </div>
    )
}
