import React from 'react';
import metropolLogo from "../assets/images/metropol-logo.svg";
import kassaIcon from "../assets/images/kassa-turkey.svg"
import assistyLogoColor from "../assets/images/assisty-logo-color.svg";
import {useTranslation} from "react-i18next";

export default function Partnership() {

    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div className="d-center flex-column brand-logos mt-4 mt-lg-0 mb-4 pt-5">
            <div className='d-center flex-column'>
                {/* <a className="d-inline-block" href="https://yoomoney.ru" target="_blank" rel="noopener noreferrer"> */}
                {/* <img src={kassaIcon} alt="img" className="yoomoney-logo" /> */}
                <img src={assistyLogoColor} alt="img" className="assisty-logo mt-3" />
            </div>
            <a className="d-center mt-4" href="https://metropolcard.com/" target="_blank" rel="noopener noreferrer">
                <span className='me-3 text-dark fw-semibold text-nowrap'>{t('ask-for')} </span>
                <img src={metropolLogo} alt="img" className="metropol-logo" />
            </a>
        </div>
    )
}