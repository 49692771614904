import React from 'react'
import Header from "./components/Header";
import FilterLocations from "./components/FilterLocations";
// import References from "./components/References";
import Numbers from "./components/Numbers";
import HowItWorks from "./components/HowItWorks";
import TouristPass from './components/TouristPass';
import Partnership from './components/Partnership';
// import Campaigns from "./components/Campaigns";
import ContactSupport from "./components/ContactSupport";
import AboutUs from './components/AboutUs';
import ContactUs from "./components/ContactUsLight";
import Footer from "./components/Footer";
import { useEffect } from "react";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      ru: { translation: translationRU },
    },
    lng: 'ru', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false,
    },
});

function App() {

	function headerFixed() {
		const headerTop = document.querySelector('.header-top');
		const paymentPoints = document.querySelector('#payment-points');
		window.addEventListener('scroll', () => {
			if (window.scrollY > headerTop.offsetHeight) {
				headerTop.classList.add('position-fixed');
				paymentPoints.style.paddingTop = headerTop.offsetHeight + "px";
			} else {
				headerTop.classList.remove('position-fixed');
				paymentPoints.style.paddingTop = ""
			}
		})
	}
	useEffect(() => {
        headerFixed();
    },[])

	return (
		<>
			<header className="top-section position-relative px-2 px-lg-0">
				<div className="container-fluid px-0 header-top">
					<Header socialButtons={false} mobileButton={false} mainClass="container position-relative py-3 px-4 px-lg-0 w-100"/>
				</div>
				<FilterLocations />
				{/* <div className="triangle d-none d-xl-block"></div> */}
			</header>
			{/* <References /> */}
			<Partnership />
			{/* <TouristPass /> */}
			<HowItWorks />
			<Numbers />
			<ContactSupport />
			<AboutUs />
			<ContactUs />
			<Footer />
		</>
	);
}

export default App;