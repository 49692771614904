import React from "react";
import aPay from "../assets/images/apay.svg";
import { useTranslation } from 'react-i18next';

export default function PaymentButton({classes,textClass}) {
	const { t } = useTranslation();
	return (
		<a href="https://qr.assistypay.com/" className={classes} title="APay" target="_blank" rel="noopener noreferrer">
			<img src={aPay} className="apay-logo" alt="Apay" />
			<span className={textClass}>{t('make-payment')}</span>			
		</a>
	);
}
