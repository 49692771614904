import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Numbers() {
    const { t } = useTranslation();

    return (
        <div className='numbers container my-5 pb-lg-5'>
            <div className="row">
                <div className="col-6 col-lg-3 number text-center">
                    <img src={require("../assets/images/icon-city.svg")}  alt={t('cities')} />
                    <h5 className='h1 my-3 fw-bold'>81</h5>
                    <p className='h5 fw-semibold'>{t('cities')}</p>
                </div>
                <div className="col-6 col-lg-3 number text-center">
                    <img src={require("../assets/images/icon-restaurant.svg")}  alt={t('restaurants')} />
                    <h5 className='h1 my-3 fw-bold'>21.126</h5>
                    <p className='h5 fw-semibold'>{t('restaurants')}</p>
                </div>
                <div className="col-6 col-lg-3 number text-center">
                    <img src={require("../assets/images/icon-cart.svg")}  alt={t('markets')} />
                    <h5 className='h1 my-3 fw-bold'>6.790</h5>
                    <p className='h5 fw-semibold'>{t('markets')}</p>
                </div>
                <div className="col-6 col-lg-3 number text-center">
                    <img src={require("../assets/images/icon-clothing.svg")}  alt={t('shopping')} />
                    <h5 className='h1 my-3 fw-bold'>2.835</h5>
                    <p className='h5 fw-semibold'>{t('shopping-small')}</p>
                </div>
            </div>
        </div>
    )
}
