import React from "react";
import whatsappIcon from "../assets/images/icon-whatsapp.svg";
import telegramIcon from "../assets/images/icon-telegram.svg";

export default function SocialButtons() {
	return (
        <div className="social-buttons">
            <a href="https://wa.me/+79182795555?text=" className="whatsapp-button" title="Whatsapp" target="_blank" rel="noopener noreferrer">
                <img src={whatsappIcon} alt="Whatsapp" />
                <span className="fs-6 fw-semibold ms-2 text-white">Whatsapp</span>
            </a>
            <a href="https://telegram.me/AssistyOnline_Bot" className="telegram-button" title="Telegram" target="_blank" rel="noopener noreferrer">
                <img src={telegramIcon} alt="Telegram" />
                <span className="fs-6 fw-semibold ms-2 text-white">Telegram</span>
            </a>
        </div>
	);
}
