import React from 'react';
import {useState} from 'react'
// import iconPos from "../assets/images/icon-pos-machine.svg";
import aPay from "../assets/images/apay.svg";
import iconRestaurant from "../assets/images/icon-restaurant-white.svg";
import iconMarkets from "../assets/images/icon-cart-white.svg";
import iconClothing from "../assets/images/icon-clothing-white.svg";
import bgRestaurants from "../assets/images/bg-restaurants.jpg";
import bgMarkets from "../assets/images/bg-markets.jpg";
import bgClothing from "../assets/images/bg-clothing.jpg";
import payInTurkeyImage from "../assets/images/payinturkey-banner-image.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PaymentPoints from './PaymentPoints';
import TurkeyMapWithMarkers from './TurkeyMapWithMarkers';
import TouristPass from './TouristPass';
import { useTranslation } from 'react-i18next';

export default function FilterLocations() {
    const { t } = useTranslation();
    const [selectedSector,setselectedSector] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setselectedSector(e.target.dataset.sector)
        localStorage.setItem('sector', e.target.dataset.title);
    };

    return (
			<div id="payment-points" className="container-fluid px-lg-0 clearfix">
				{/* <div className="container position-relative px-0" style={{'zIndex':"2"}}> */}
				<div className="container position-relative px-0">
					{/* <h1 className='text-white text-center fw-bold pt-5 w-100 w-lg-50 mx-auto h2'>{t('footer-motto-line-1')}!</h1> */}
					{/* <div className="text-center mt-4 mt-lg-5">
						<a className='btn btn-qr btn-primary d-inline-block' href="#about-us">{t('about-us')}</a>
					</div> */}
					{/* <h3 className='text-white text-center fw-semibold mt-4 mt-lg-5 pt-3 mb-4'>{t('payment-points')}</h3> */}

					{/* prettier-ignore */}
					<div className="container px-0 pt-5 pb-lg-5 mt-lg-4">
                    <div className="row align-items-center">
                        <div className="d-flex flex-column align-items-center col-12 text-center text-white mt-3">
                            <h1>{t('payinturkey-title')}</h1>
                            <p>{t('payinturkey-desc')}</p>
                            <a href="https://qr.assistypay.com/" target='_blank' className='btn btn-qr mt-4 px-5' title='Pay In Turkey'>{t('payinturkey-button-text')}</a>
                            <a href="https://payinturkey.com/" target='_blank' className='btn btn-pay mt-4 px-5 d-none' title='Pay In Turkey'>{t('payinturkeycom-button-text')}</a>
                        </div>
                        <div className="col-12 col-lg-5 mt-4 mt-lg-0 d-none">
                            <img src={payInTurkeyImage} alt="PayInTurkey" className='w-100 d-block mx-auto' style={{maxWidth:"480px"}} />
                        </div>
                    </div>
                </div>

					<TurkeyMapWithMarkers />
					<div className="filter-locations container mb-5">
						<div className="filter-locations-inner px-3 py-4 row">
							<div className="col-12 col-lg-4">
								<div className="filter-location position-relative">
									<div className="image-wrapper w-100">
										<img src={bgRestaurants} alt="img" className="w-100" />
									</div>
									<p className="m-0 position-absolute">
										<img src={iconRestaurant} alt="" />
										<span className="ms-2">{t("restaurants")}</span>
									</p>
									<Button
										className="btn btn-danger position-absolute"
										data-sector={t("list-restaurants")}
										data-title="restaurant"
										variant="primary"
										onClick={handleShow}
									>
										{t("show-list")}
									</Button>
									<a
										href="https://qr.assistypay.com/#qr"
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-danger position-absolute"
									>
										<img src={aPay} className="apay-logo" alt="img" />
									</a>
								</div>
							</div>
							<div className="col-12 col-lg-4 my-5 my-lg-0">
								<div className="filter-location position-relative">
									<div className="image-wrapper w-100">
										<img src={bgMarkets} alt="img" className="w-100" />
									</div>
									<p className="m-0 position-absolute">
										<img src={iconMarkets} alt="" />
										<span className="ms-2">{t("markets")}</span>
									</p>
									<Button
										className="btn btn-danger position-absolute"
										data-sector={t("list-markets")}
										data-title="market"
										variant="primary"
										onClick={handleShow}
									>
										{t("show-list")}
									</Button>
									<a
										href="https://qr.assistypay.com/#qr"
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-danger position-absolute"
									>
										<img src={aPay} className="apay-logo" alt="img" />
									</a>
								</div>
							</div>
							<div className="col-12 col-lg-4 mb-2 mb-lg-0">
								<div className="filter-location position-relative">
									<div className="image-wrapper w-100">
										<img src={bgClothing} alt="img" className="w-100" />
									</div>
									<p className="m-0 position-absolute">
										<img src={iconClothing} alt="" />
										<span className="ms-2">{t("shopping")}</span>
									</p>
									<Button
										className="btn btn-danger position-absolute"
										data-sector={t("list-shopping")}
										data-title="shopping"
										variant="primary"
										onClick={handleShow}
									>
										{t("show-list")}
									</Button>
									<a
										href="https://qr.assistypay.com/#shortcode"
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn-danger position-absolute"
									>
										<img src={aPay} className="apay-logo" alt="img" />
									</a>
								</div>
							</div>
						</div>
					</div>
					<TouristPass />
				</div>
				<Modal show={show} onHide={handleClose} scrollable>
					<Modal.Header className="align-items-start align-items-lg-center px-0 py-4 mx-4" closeButton>
						<Modal.Title className="fw-semibold">
							{t("filter-locations")}
							<br className="d-block d-lg-none" /> ({selectedSector})
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-4">
						<PaymentPoints />
					</Modal.Body>
				</Modal>
			</div>
		);
}